body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background-color: #000;
  }
  
  .login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #000;
  }
  
  .login-box {
    background-color: #222;
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .title {
    color: #e74c3c;
    font-size: 28px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .subtitle {
    color: #ccc;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  label {
    color: #ccc;
    text-align: left;
    font-size: 14px;
  }

  #Title {
    color: #e74c3c;
    text-decoration: underline;
  }
  
  input {
    padding: 10px;
    background-color: #333;
    border: 1px solid #e74c3c;
    border-radius: 5px;
    color: #fff;
  }
  
  input::placeholder {
    color: #999;
  }
  
  .forgot-password {
    color: #e74c3c;
    text-decoration: none;
    font-size: 14px;
  }
  
  button {
    margin-top: 20px;
    padding: 12px;
    background-color: #e74c3c;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #c0392b;
  }
  
  .register-text {
    margin-top: 20px;
    color: #ccc;
  }
  
  .register-text a {
    color: #e74c3c;
    text-decoration: none;
  }
