body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #121212;
}

.signup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(135deg, #1e1e1e, #121212);
}

.signup-box {
  background-color: #222;
  padding: 40px;
  width: 400px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  animation: fadeIn 0.5s ease-in-out;
}

.title {
  color: #e74c3c;
  font-size: 32px;
  margin-bottom: 10px;
  font-weight: bold;
}

#Title {
  color: #e74c3c;
  text-decoration: underline;
  font-size: 20px;
  margin-bottom: 20px;
}

.subtitle {
  color: #bbb;
  margin-bottom: 25px;
  font-size: 16px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

label {
  color: #bbb;
  text-align: left;
  font-size: 14px;
}

input {
  padding: 12px;
  background-color: #333;
  border: 1px solid #555;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input:focus {
  outline: none;
  border-color: #e74c3c;
  box-shadow: 0 0 8px rgba(231, 76, 60, 0.8);
}

input::placeholder {
  color: #aaa;
}

button {
  margin-top: 20px;
  padding: 14px;
  background: linear-gradient(135deg, #e74c3c, #c0392b);
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease, background 0.3s ease;
}

button:hover {
  background: linear-gradient(135deg, #c0392b, #a93226);
  transform: scale(1.05);
}

button:active {
  transform: scale(0.95);
}

.login-text {
  margin-top: 20px;
  color: #bbb;
  font-size: 14px;
}

.login-text a {
  color: #e74c3c;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.login-text a:hover {
  color: #c0392b;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
