@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans+Collegiate+One:ital@0;1&display=swap');

/* Custom styles for React Toastify */
.Toastify__toast-container {
    width: 320px;
}

.Toastify__toast {
    background-color: #222;
    color: #ccc;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.Toastify__toast--success {
    background-color: #2ecc71;
}

.Toastify__toast--error {
    background-color: #e74c3c;
}

.Toastify__toast--warning {
    background-color: #f39c12;
}

.Toastify__toast--info {
    background-color: #3498db;
}

.Toastify__progress-bar {
    background-color: rgba(255, 255, 255, 0.7);
}

.Toastify__close-button {
    color: #ccc;
    opacity: 0.7;
}

.Toastify__close-button:hover {
    opacity: 1;
}

.custom-cursor {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 9999;
    background: radial-gradient(circle, #ff4444 0%, #ff0000 60%, transparent 100%);
    box-shadow: 0 0 20px rgba(255, 68, 68, 0.8);
    mix-blend-mode: screen;
    transition: transform 0.1s ease-out;
}

.custom-cursor.hovered {
    transform: scale(2);
    background: radial-gradient(circle, #ffae00 0%, #ff4500 60%, transparent 100%);
    box-shadow: 0 0 30px rgba(255, 174, 0, 0.8);
}

